$border-color: rgba(0, 0, 0, 0.15);
$primary-red-color: #E5051D;
$primary-darkred-color: #c70000;
$primary-darkred-border-color: #a50000;
$main-background-color: #282b2e;

// navbar
$navbar-color-show: rgba(0, 0, 0, 0.7);

// heading
$heading-prefix-color: #b02b2c;

// footer
$footer-color-lightgrey: #e8e8e8;
$footer-color-grey: #7c7c7c;
//$footer-color-darkgrey: #35383c;
$footer-background-color: #282b2e;

// login
$login-background-color: #8891A7;

// input
$input-background-color: #e8eff2;
