/* lato-100italic - latin-ext_latin */
@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 100;
    src: url('../../font/lato/lato-v23-latin-ext_latin-100italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../../font/lato/lato-v23-latin-ext_latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../font/lato/lato-v23-latin-ext_latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../font/lato/lato-v23-latin-ext_latin-100italic.woff') format('woff'), /* Modern Browsers */
    url('../../font/lato/lato-v23-latin-ext_latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../font/lato/lato-v23-latin-ext_latin-100italic.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-300 - latin-ext_latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    src: url('../../font/lato/lato-v23-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../../font/lato/lato-v23-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../font/lato/lato-v23-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../font/lato/lato-v23-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
    url('../../font/lato/lato-v23-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../font/lato/lato-v23-latin-ext_latin-300.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-regular - latin-ext_latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url('../../font/lato/lato-v23-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../../font/lato/lato-v23-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../font/lato/lato-v23-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../font/lato/lato-v23-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../../font/lato/lato-v23-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../font/lato/lato-v23-latin-ext_latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}
