@import "colors";

html {
    background-color: $main-background-color;
    border: 0;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    border: 0;
    margin: 0;
    overflow-x: hidden;
    padding: 0;
}

#main {
    background-color: white;
    border: 0;
    box-sizing: border-box;
    flex: 1;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: static;
    width: 100%;
    //z-index: 2;
}

.container {
    box-sizing: border-box;
    clear: both;
    max-width: 1400px;
    position: relative;
    width: 100%;
}

@include media-breakpoint-up(xl) {
    .container {
        padding: 0 50px;
    }
}

div.message {
    color: $primary-red-color !important;
    font-size: 12pt;
    font-weight: bold;
    min-height: 30px;
    text-align: center;
}

.spinner-hidden {
    visibility: hidden !important;
}

.data-table {
    min-height: 25px;
}

.table-responsive {
    margin-bottom: 1rem;
}

.no-border * {
    border: none;
}

.whitespace-nowrap {
    white-space: nowrap;
}

.inline-block {
    display: inline-block;
}

.primary-darkred-color {
    color: $primary-darkred-color;
}

.admin {
    color: $primary-red-color;
}
