@import "colors";

div.my_form {
    background-color: $input-background-color;
    border-radius: 5px;
    margin: auto;
    padding: 0.75rem;
    z-index: 0;
}

.form-check * {
    user-select: none;
}

.my_form-check-input:checked {
    background-color: $primary-darkred-color;
    border-color: $primary-darkred-border-color;
}

.schaltflaeche {
    background-color: grey;
    border: none black;
    border-radius: 2px;
    color: white;
    cursor: pointer;
    font-size: 0.92em;
    font-weight: normal;
    margin: 0;
    min-width: 142px;
    outline: none;
    padding: 8px 20px;
    transition: all 0.3s ease-in-out;

    &:not(.disabled) {
        background-color: $primary-darkred-color;
    }

    &:hover {
        &:not(.disabled) {
            color: Black;
        }
    }
}

.button-light {
    cursor: pointer;
    padding: 3px;

    &.active {
        background-color: $primary-darkred-color;
        color: white;
    }
}

.eingabefeld {
    -webkit-appearance: none;
    background: white;
    border: 1px solid $border-color;
    border-radius: 2px;
    display: inline;
    font: 1em "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 0;
    min-width: 50px;
    outline: none;
    padding: 13px;
    transition: all 0.3s ease-in-out;
    width: 100%;
}

.page-link {
    color: black;
    user-select: none;
}

.page-item:not(.active) > .page-link:hover, .page-item:not(.active) > .page-link:focus {
    color: black;
}

.page-item.active .page-link {
    background-color: $primary-darkred-color;
    border-color: gray;
    color: white;
}
