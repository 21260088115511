@import "colors";

.heading-prefix {
    margin-top: 0;
    margin-bottom: 10px;

    & span {
        width: 50px;
        border-color: $heading-prefix-color;
        border-top-width: 2px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        height: 1px;
        left: 0;
        top: 50%;
        margin-top: 43px;
        border-top-style: solid;
    }
}

h1 {
    font-family: 'lato', 'HelveticaNeue', 'Helvetica Neue', 'Helvetica-Neue', Helvetica, Arial, sans-serif;
    font-size: 38px;
    font-weight: lighter;
    letter-spacing: 1px;
    text-transform: none;
}

h3 {
    font-family: 'lato', 'HelveticaNeue', 'Helvetica Neue', 'Helvetica-Neue', Helvetica, Arial, sans-serif;
    font-size: 20px !important;
    font-weight: lighter;
    letter-spacing: 1px;
    line-height: 1.1em;
    margin-bottom: 8px;
    margin-top: 0.85em;
    text-transform: none;
}
