@import "colors";

.header.navbar {
    background-color: white;
    border-bottom: 1px solid $border-color;
    user-select: none;
}

.header.navbar .navbar-nav .show {
    color: $navbar-color-show;
}

.navbar-dropdown-item {
    margin: 0;
    padding: 0.25rem 1rem;
    white-space: nowrap;
}

#userMenuButton {
    align-items: center;
    display: flex;
}

.user-specific-icon {
    margin-right: 1rem;
}

.user-generic-icon {
    display: none;
}

@include media-breakpoint-down(xl) {
    .user-specific-icon {
        display: none;
    }

    .user-generic-icon {
        display: inline-block;
    }
}
