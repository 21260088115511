@import "colors";

footer.navbar {
    background-color: $footer-background-color;
    color: $footer-color-lightgrey;
    padding: 1rem 0;

    .container-fluid {
        max-width: 1300px;
    }

    * {
        font-size: 0.75rem;
    }

    a {
        color: $footer-color-lightgrey;
        text-decoration: none;

        &:hover {
            color: white;
            text-decoration: underline;
        }
    }
}

#footerinfo {
    background-color: white;
    color: $footer-color-grey;
    padding-top: 3rem;
    padding-bottom: 3rem;
    text-align: center;

    a {
        color: $footer-color-grey;

        &:hover {
            color: black;
        }
    }
}
