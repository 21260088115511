@import "colors";

div.login {
    font-family: Arial, Helvetica, sans-serif;
    margin: 100px auto auto;
    min-height: 100vw;
    width: 500px;
}

div.login_header {
    height: 60px;
    z-index: 0;
}

div.login_key {
    left: 210px;
    position: relative;
    top: 38px;
    z-index: 8;
}

div.login_form {
    background-color: #C7D1E0;
    border-radius: 5px;
    padding: 10px;
    z-index: 0;
    min-height: 320px;
}

input.login {
    border: 1px solid $border-color;
    border-radius: 5px;
    font-size: 12pt;
    padding: 10px;
    width: 99%;
}

input.login_button {
    background-color: $login-background-color;
    border: 1px solid $border-color;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    font-size: 12pt;
    padding: 10px;
    text-align: left;
    width: 99%;
}

input.login_guest {
    background-color: white;
    border: 1px solid $border-color;
    border-radius: 5px;
    color: $primary-red-color;
    cursor: pointer;
    font-size: 12pt;
    font-weight: bold;
    margin-top: 40px;
    padding: 10px;
    text-align: left;
    width: 150px;
}

div.login {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11pt;
    margin: auto;
    overflow: hidden;
}
